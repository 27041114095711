import { isEmpty } from 'lodash';

// TYPES
import { AppThunk } from 'redux/type';

// BASE ACTION
import { httpAction } from 'redux/base/action';

// ROUTER
import { ApiProfile, LovedOneApi } from 'constant';

// MODEL
import { User, ProfileLinkingParams, ProfileLinkingResponse, ProfileResendCodeParams, CheckingProfile } from 'model';

// HELPERS
import { pushData, changeValueOfItemFromArr, removeData } from 'helpers/array';

// TYPE
import { Callback } from 'interface';

// APP ACTIONS
import { setAppMessage } from 'redux/app/actions';

// SLICE
import { selectEnabledErrorMessageForLinkPass } from 'redux/featureFlag/selectors';
import { userSlice, initialSingleUser } from './slice';

// SELECTOR
import { selectManyUsers } from './selector';

// ACTIONS
const { changeUserLoading, setLoggedInUser, setManyUsers, setUser } = userSlice.actions;

export const reformatDataForRedux = (user: User): User => {
  let reformatData = {
    ...user
  };

  Object.keys(reformatData).forEach((v) => {
    if (!reformatData[v] && typeof reformatData[v] !== 'boolean') {
      reformatData[v] = '';
    }

    if (reformatData[v] === ' ' && typeof reformatData[v] === 'string') {
      reformatData[v] = '';
    }
  });

  if ((reformatData.language as string).length > 0) {
    reformatData = {
      ...reformatData,
      language: (reformatData.language as string).split(', ')
    };
  }

  if (
    reformatData.residentStatus &&
    (reformatData.residentStatus as string).length > 0 &&
    reformatData.residentStatus === 'Singapore/ Pink IC'
  ) {
    reformatData = {
      ...reformatData,
      nationality: 'SG'
    };
  }

  if ((reformatData.language as string).length === 0) {
    reformatData = {
      ...reformatData,
      language: []
    };
  }

  if (!reformatData.idType || reformatData.idType.length === 0) {
    reformatData = {
      ...reformatData,
      idType: 'NRIC'
    };
  }

  return reformatData;
};

export const reformatSubmitData = (contact: User): User => {
  let data = { ...contact };

  if (data.residentStatus !== 'Others') {
    data = {
      ...data,
      otherResidentStatus: ''
    };
  }

  if (
    data.residentStatus &&
    (data.residentStatus as string).length > 0 &&
    data.residentStatus === 'Singapore/ Pink IC' &&
    data.nationality !== 'SG'
  ) {
    data = {
      ...data,
      nationality: 'SG'
    };
  }

  if (data.religion !== 'Others') {
    data = {
      ...data,
      otherReligion: ''
    };
  }

  if (!(data.language as string[]).includes('Others')) {
    data = {
      ...data,
      otherLanguage: ''
    };
  }

  if (isEmpty(contact.homeNumber)) {
    data = {
      ...data,
      homeNumber: null
    };
  }

  if (Array.isArray(data.language)) {
    data = {
      ...data,
      language: data.language.join(', ')
    };
  }

  delete data.otherNationality;

  return data;
};

const getPersonalDetail =
  (callback?: Callback<User>): AppThunk =>
  async (dispatch) => {
    dispatch(changeUserLoading(true));

    dispatch(
      httpAction<unknown, unknown, User>(
        {
          method: 'GET',
          url: ApiProfile.Profile,
          needAuth: true,
          hideToast: true
        },
        async (res) => {
          dispatch(changeUserLoading(false));

          if (res.data) {
            const reformatData = reformatDataForRedux(res.data);

            dispatch(setLoggedInUser(reformatData));
          }

          if (callback) {
            callback(res.data);
          }
        }
      )
    );
  };

const updatePersonalDetail =
  (user: User, callback?: Callback<User>): AppThunk =>
  async (dispatch, getState) => {
    dispatch(changeUserLoading(true));

    const currentManyUsers = selectManyUsers(getState());
    const immutableManyUsers = changeValueOfItemFromArr<User>(currentManyUsers, user);
    const updateUserParam = reformatSubmitData(user);

    dispatch(
      httpAction<User, unknown, User>(
        {
          method: 'PUT',
          url: ApiProfile.Profile,
          data: updateUserParam,
          needAuth: true
        },
        async (res) => {
          if (res.data) {
            const reformatData = reformatDataForRedux(res.data);

            dispatch(
              setAppMessage({
                type: 'success',
                content: 'Updated personal detail successfully'
              })
            );

            dispatch(setLoggedInUser(reformatData));
            dispatch(setManyUsers(immutableManyUsers));
          }

          dispatch(changeUserLoading(false));

          if (callback) {
            callback(res.data);
          }
        }
      )
    );
  };

// GET MANY LOVED ONES
const getLovedOnes =
  (callback?: Callback<User[]>): AppThunk =>
  async (dispatch) => {
    dispatch(changeUserLoading(true));

    dispatch(
      httpAction<unknown, unknown, User[]>(
        {
          method: 'GET',
          url: LovedOneApi.lovedOne,
          needAuth: true
        },
        async (res) => {
          dispatch(changeUserLoading(false));

          const data = res.data || [];

          const formatedArr = data.map((v) => ({
            ...initialSingleUser,
            id: v.id,
            name: v.name
          }));

          dispatch(setManyUsers(formatedArr));

          if (callback) {
            callback(data);
          }
        }
      )
    );
  };

// GET MANY LOVED ONE DETAIL
const getLovedOneDetailById =
  (id: string, callback?: Callback<User>): AppThunk =>
  async (dispatch) => {
    dispatch(changeUserLoading(true));

    dispatch(
      httpAction<unknown, unknown, User>(
        {
          method: 'GET',
          url: `${LovedOneApi.lovedOne}/${id}`,
          needAuth: true
        },
        async (res) => {
          dispatch(changeUserLoading(false));

          if (res.data) {
            const reformatData = reformatDataForRedux(res.data);

            dispatch(setUser(reformatData));

            if (callback) {
              callback(reformatData);
            }
          }
        }
      )
    );
  };

// UPDATE LOVED ONE DETAIL BY ID
const updateLovedOneDetailById =
  (currentLovedOne: User, callback?: Callback<User>): AppThunk =>
  async (dispatch, getState) => {
    dispatch(changeUserLoading(true));

    const currentManyUsers = selectManyUsers(getState());
    const immutableManyUsers = changeValueOfItemFromArr<User>(currentManyUsers, currentLovedOne);
    const updateLovedOneParam = reformatSubmitData(currentLovedOne);

    dispatch(
      httpAction<User, unknown, User>(
        {
          method: 'PUT',
          url: `${LovedOneApi.lovedOne}/${currentLovedOne.id}`,
          data: updateLovedOneParam,
          needAuth: true
        },
        async (res) => {
          dispatch(changeUserLoading(false));

          if (res.data) {
            dispatch(
              setAppMessage({
                type: 'success',
                content: 'Updated successfully'
              })
            );

            dispatch(setUser(reformatDataForRedux(res.data)));
            dispatch(setManyUsers(immutableManyUsers));
          }

          if (callback) {
            callback(res.data);
          }
        }
      )
    );
  };

// CREATE A NEW LOVED ONE
const createNewLovedOne =
  (currentLovedOne: User, callback?: Callback<User>): AppThunk =>
  async (dispatch, getState) => {
    dispatch(changeUserLoading(true));

    const currentManyUsers = selectManyUsers(getState());
    const createLovedOneParam = reformatSubmitData(currentLovedOne);

    dispatch(
      httpAction<User, unknown, User>(
        {
          method: 'POST',
          url: LovedOneApi.lovedOne,
          data: createLovedOneParam,
          needAuth: true
        },
        async (res) => {
          dispatch(changeUserLoading(false));

          if (res.data) {
            dispatch(
              setAppMessage({
                type: 'success',
                content: 'Created your loved one successfully'
              })
            );

            dispatch(setManyUsers(pushData<User>(currentManyUsers, res.data, reformatDataForRedux)));
          }

          if (callback) {
            callback(res.data ? reformatDataForRedux(res.data) : undefined);
          }
        }
      )
    );
  };

const removedLovedOne =
  (currentLovedOne: User, callback?: Callback<User>): AppThunk =>
  async (dispatch, getState) => {
    dispatch(changeUserLoading(true));

    const currentManyUsers = selectManyUsers(getState());
    const immutableManyUsers = removeData<User>(currentManyUsers, currentLovedOne);

    dispatch(
      httpAction<unknown, unknown, User>(
        {
          method: 'POST',
          url: `${LovedOneApi.lovedOne}/${currentLovedOne.id}`,
          needAuth: true
        },
        async (res) => {
          dispatch(changeUserLoading(false));

          if (res.data) {
            dispatch(
              setAppMessage({
                type: 'success',
                content: `Removed ${currentLovedOne.name} successfully`
              })
            );

            dispatch(setManyUsers(immutableManyUsers));
          }

          if (callback) {
            callback(res.data);
          }
        }
      )
    );
  };

// CREATE PROFILE LINKING
const createProfileLinking =
  (params: ProfileLinkingParams, callback?: Callback<ProfileLinkingResponse>): AppThunk =>
  async (dispatch, getState) => {
    dispatch(changeUserLoading(true));

    dispatch(
      httpAction<ProfileLinkingParams, unknown, ProfileLinkingResponse>(
        {
          method: 'POST',
          url: ApiProfile.ProfileLinking,
          data: params,
          needAuth: true
        },
        async (res) => {
          dispatch(changeUserLoading(false));
          const enabledLinkPassErrorMessage = selectEnabledErrorMessageForLinkPass(getState());

          let msg = '';

          if (enabledLinkPassErrorMessage) {
            const PROFILE_LINK_INVALID_CLIENT_ID_ERROR_MSG = 'The referral code does not exist';
            const PROFILE_LINK_INVALID_NRIC_ERROR_MSG = 'The referral code is not valid for the provided NRIC';
            const emailContent = `<span style="color:green; text-decoration: underline">support@ntuchealth.sg</span>`;

            if (res.message?.includes(PROFILE_LINK_INVALID_CLIENT_ID_ERROR_MSG)) {
              msg = `Incorrect Client ID. Please check case sensitivity and try again. For assistance, contact ${emailContent}.`;
            } else if (res.message === PROFILE_LINK_INVALID_NRIC_ERROR_MSG) {
              msg = `Incorrect identification details. Please verify your NRIC, passport ID, or other info and try again. For assistance, contact ${emailContent}.`;
            } else {
              msg = `Technical difficulties. Please retry later or contact ${emailContent} for help.`;
            }

            if (!isEmpty(msg)) {
              dispatch(
                setAppMessage({
                  type: 'support',
                  content: msg,
                  ignoreEmailSupportingMessage: true
                })
              );
            }
          }

          if (res.data && isEmpty(msg) && !enabledLinkPassErrorMessage) {
            dispatch(
              setAppMessage({
                type: 'success',
                content: 'Created profile linking successfully'
              })
            );
          }

          if (callback) {
            callback(res.data);
          }
        }
      )
    );
  };

// GET PROFILE LINKING
const getProfileLinking =
  (nric: string, linkingType: string, lovedOne?: string, callback?: Callback<ProfileLinkingResponse>): AppThunk =>
  async (dispatch) => {
    dispatch(changeUserLoading(true));

    let url = `${ApiProfile.ProfileLinking}?nric=${nric}&linkingType=${linkingType}`;

    if (!isEmpty(lovedOne)) {
      url = `${url}&lovedOne=${lovedOne}`;
    }

    dispatch(
      httpAction<unknown, unknown, ProfileLinkingResponse>(
        {
          method: 'GET',
          url,
          needAuth: true,
          hideToast: true
        },
        async (res) => {
          dispatch(changeUserLoading(false));

          if (callback) {
            callback(res.data);
          }
        }
      )
    );
  };

// CREATE PROFILE LINKING
const resendLinkingCode =
  (params: ProfileResendCodeParams, callback?: Callback<boolean>): AppThunk =>
  async (dispatch) => {
    dispatch(changeUserLoading(true));

    dispatch(
      httpAction<ProfileResendCodeParams, unknown, ProfileResendCodeParams>(
        {
          method: 'POST',
          url: ApiProfile.ResendCode,
          data: params,
          needAuth: true
        },
        async (res) => {
          dispatch(changeUserLoading(false));

          if (res.httpCode === 200) {
            dispatch(
              setAppMessage({
                type: 'success',
                content: `Client ID Has Been Sent To Your ${
                  params.channel === 'EMAIL' ? 'Email Address' : 'Mobile Number'
                }`
              })
            );

            if (callback) {
              callback(true);
            }

            return;
          }

          if (callback) {
            callback(false);
          }
        }
      )
    );
  };

// GET PROFILE BY NRIC
const getProfileByNric =
  (nric: string, isHideErrorToast: boolean = false, callback: Callback<CheckingProfile>): AppThunk =>
  async (dispatch) => {
    dispatch(
      httpAction<unknown, unknown, User>(
        {
          method: 'GET',
          url: ApiProfile.GetProfileByNric(nric),
          needAuth: true,
          hideToast: isHideErrorToast
        },
        async (res) => {
          callback({ isNotFound: res.statusCode === 404, user: res.data });
        }
      )
    );
  };

export {
  updatePersonalDetail,
  getPersonalDetail,
  setUser,
  getLovedOnes,
  getLovedOneDetailById,
  updateLovedOneDetailById,
  createNewLovedOne,
  removedLovedOne,
  createProfileLinking,
  getProfileLinking,
  resendLinkingCode,
  getProfileByNric
};
